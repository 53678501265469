import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { useIntl } from 'gatsby-plugin-intl'
import 'material-icons/iconfont/material-icons.css'

//COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'

//STYLES
import '../styles/privacyPolicy.css'

const PrivacyPolicy = ({ data }) => {
  const intl = useIntl()

  return (
    <div id="privacypolicy">
      <BackgroundImage
        className="header-img"
        fluid={data.privacyImage.childImageSharp.fluid}
        alt="Recruite header image"
      >
        <Header />
        <div className="selling-text">
          <h1>Integritetspolicy</h1>
        </div>
      </BackgroundImage>
      <main>
        <h2 className="privacypolicy-header">
          Integritetspolicy för vår hemsida
        </h2>
        <div className="privacypolicy-container">
          <h3>Behandling av personuppgifter på Cloud Enablers AB</h3>
          <p>
            För Cloud Enablers AB, org.nr 556957-5078, är personlig integritet
            viktigt. Vi eftersträvar en hög nivå av dataskydd. I denna policy
            förklarar vi hur vi samlar in och använder personuppgifter. Du är
            alltid välkommen att kontakta oss om du har frågor om hur vi
            behandlar dina personuppgifter. Kontaktuppgifter står sist i denna
            text.
          </p>{' '}
          <h3>
            1. Vad är en personuppgift och vad är en behandling av
            personuppgifter?
          </h3>
          <p>
            Personuppgifter är alla uppgifter om en levande fysisk person som
            direkt eller indirekt kan kopplas till den personen. Det handlar
            inte bara om namn och personnummer utan även om till exempel bilder,
            e-postadresser eller ip-adresser. Behandling av personuppgifter är
            allt som sker med personuppgifterna i IT-systemen, oavsett om det
            handlar om mobila enheter eller datorer.
          </p>
          <h3>2. Vilka personuppgifter samlar vi in om dig och varför?</h3>
          <p>
            {' '}
            Vi behandlar i huvudsak din geografiska postition. Vi samlar in dina
            personuppgifter eftersom vi vill förbättra din upplevelse på vår
            hemsida. Insamling av personuppgifter sker alltså endast för
            särskilda, uttryckligt angivna, och berättigade ändamål och vi
            samlar enbart in de uppgifter som behövs för ändamålet. Vilka
            personuppgifter vi behandlar beror på vad ändamålet med behandlingen
            är.
          </p>
          <h3>3. Cloud Enablers behandling av cookies</h3>
          <h3>3.1 Vad är ett cookie för något?</h3>
          <p>
            Cookies är små textfiler som sparas i din webbläsare när du besöker
            vissa hemsidor och webbutiker. De används bland annat för att
            möjliggöra funktioner och ge webbplatsägaren statistik om ditt
            besök.
          </p>
          <h3>3.2 Hur använder vi cookies?</h3>
          <p>
            Cloud Enablers använder sig utav förstaparts cookies. Vi gör detta i
            huvudsak för att säkerställa sidans funktionalitet .{' '}
          </p>
          <h3>3.3 Vilka cookies sparar vi?</h3>
          <h3>Analytics</h3>
          <p>
            Vi använder monitorerings-verktygen Google Analytics och New Relic
            som med hjälp av cookies analyserar hur du som besökare integrerar
            med vår hemsida. Denna information använder vi för att förbättra vår
            hemsida. Genom att vi använder Google Analytics lagrar vi din
            IP-adress och annan information som de cookies vi placerar på din
            webbläsare samlar in. Detta kan vara data som;
            <ul>
              <li>Modell på telefon</li>
              <li>Operativsystem</li>
              <li>Skärmupplösning</li>
              <li>Webbläsartyp</li>
              <li>Tid på hemsidan</li>
              <li>Hur du rör vid runt på hemsidan</li>
            </ul>
          </p>
          <h3>4. Hur länge sparar vi dina personuppgifter?</h3>
          <p>
            {' '}
            Dina uppgifter sparas i max 2 månader hos Google Analytics och i 1
            månad hos New Relic. Internt hos Cloud Enablers sparas
            personuppgifter endast så länge vi har användning av de.
          </p>
          <h3>5. Vem får tillgång till dina personuppgifter?</h3>
          <p>
            Dina personuppgifter används primärt för internt bruk för anställda
            hos Cloud Enablers AB. Som anställd hos Cloud Enablers kan dina
            personuppgifter delas med våra kunder. Endast personer som behöver
            tillgång till uppgifterna för att utföra sina arbetsuppgifter har
            tillgång till dem.
          </p>
          <h3>Kontaktuppgifter</h3>
          <p>
            Vi tar emot frågor angående vår Integritetspolicy till mailadressen
            nicklas.hafner@cloudenablers.se
          </p>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query privacyQuery {
    allMarkdownRemark(
      filter: { frontmatter: { key: { eq: "privacyPolicy" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
    privacyImage: file(relativePath: { eq: "recruite-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default PrivacyPolicy
